import { StatusCodes } from 'http-status-codes';
import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import { BulkUpdateDiscrepanciesRequest } from 'api/types/flags';
import { useCurrentSeason } from 'app/useCurrentSeason';
import { FlagKeys } from 'hooks/useFlagStats/queries';

export default function useBulkUpdateDiscrepanciesState() {
    const queryClient = useQueryClient();
    const { league, seasonYear, seasonType } = useCurrentSeason();

    return useMutation(
        async (discrepancy: BulkUpdateDiscrepanciesRequest) => {
            return client.patch(`${API.FLAGS_API}/discrepancy`, discrepancy);
        },
        {
            onSuccess: (data) => {
                if (data.status === StatusCodes.OK) {
                    queryClient.invalidateQueries(
                        FlagKeys.flagsStats.getFlagsStats({ league, seasonYear, seasonType }),
                    );
                    queryClient.invalidateQueries(
                        FlagKeys.flags.getFlags({ league, seasonYear, seasonType }),
                    );
                }
            },
            onError: async (error) => {
                console.error(error);
            },
            retry: 2,
        },
    );
}
