import { useQuery } from 'react-query';

import API from '../../api';
import client from '../../api/client';

import { GetFlagStatsParameters, FlagKeys } from './queries';
import { StatsResponse } from './types';

const getFlagStats = async ({
    contextObjectId,
    league,
    ...parameters
}: GetFlagStatsParameters): Promise<StatsResponse> => {
    const urlSearchParams = new URLSearchParams({
        ...(contextObjectId && { contextObjectId }),
        ...parameters,
    });
    const response = await client.get(`${API.FLAGS_API}/${league}/stats?${urlSearchParams}`);

    return response?.data;
};

export function useGetFlagStats(parameters: GetFlagStatsParameters) {
    return useQuery({
        queryKey: parameters.contextObjectId
            ? FlagKeys.flagsStats.getFlagsStatsByContextId(parameters)
            : FlagKeys.flagsStats.getFlagsStats(parameters),
        queryFn: () => getFlagStats(parameters),
        refetchOnWindowFocus: false,
    });
}
