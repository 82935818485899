import { DiscrepancyStatus } from 'api/types/flags';
import { LeagueType, SeasonType, SeasonYear } from 'types';
import { Order } from 'types/sorting';

export type GetFlagStatsParameters = {
    contextObjectId?: string;
    league: LeagueType;
    seasonType: SeasonType;
    seasonYear: SeasonYear;
};

export type GetFlagsParameters = {
    conferenceAlias?: string;
    contextObjectId?: string;
    dateFrom?: string;
    dateTo?: string;
    discrepancyStatus?: DiscrepancyStatus;
    divisionAlias?: string;
    flagType?: string;
    league: LeagueType;
    seasonType: SeasonType;
    seasonYear: SeasonYear;
    sortBy?: string;
    sortOrder?: Order;
    week?: string;
    pollsWeek?: string;
};

export type GetFlagDiscrepanciesParameters = {
    flagId: string;
    league: LeagueType;
    seasonYear: SeasonYear;
    seasonType: SeasonType;
    status?: string;
};

export const FlagKeys = {
    flagsStats: {
        getFlagsStats: ({ league, seasonYear, seasonType }: GetFlagStatsParameters) => {
            return [league, seasonYear, seasonType, 'flagStats'];
        },
        getFlagsStatsByContextId: ({ contextObjectId, ...parameters }: GetFlagStatsParameters) => {
            return [...FlagKeys.flagsStats.getFlagsStats(parameters), contextObjectId];
        },
    },
    flags: {
        getFlags: ({ league, seasonYear, seasonType }: Partial<GetFlagsParameters>) => {
            return [league, seasonYear, seasonType, 'flags'];
        },
        getFlagsByFilters: (parameters: GetFlagsParameters) => {
            return [...FlagKeys.flags.getFlags(parameters), parameters];
        },
        getFlagsByContextId: ({ contextObjectId, ...parameters }: Partial<GetFlagsParameters>) => {
            return [...FlagKeys.flags.getFlags(parameters), contextObjectId];
        },
        getFlagsByContextIdByFilters: ({ contextObjectId, ...parameters }: GetFlagsParameters) => {
            return [...FlagKeys.flags.getFlags(parameters), contextObjectId, parameters];
        },
    },
    flagDiscrepancies: {
        getFlagDiscrepancies: ({ flagId, ...parameters }: GetFlagDiscrepanciesParameters) => {
            return [...FlagKeys.flags.getFlags(parameters), flagId, 'discrepancies'];
        },
        getFlagDiscrepanciesByFilters: (parameters: GetFlagDiscrepanciesParameters) => {
            return [...FlagKeys.flagDiscrepancies.getFlagDiscrepancies(parameters), parameters];
        },
    },
};
