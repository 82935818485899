import { IconDecorated } from 'components/icons';
import { useLeagueGroupLogo } from 'hooks/useLeagueGroupLogo';
import { LeagueDetails, LeagueGroup } from 'types';

import LeagueItem from '../league-item';

import { LeagueGroupColumnContainer } from './styles';

type Props = {
    leagueGroup: LeagueGroup;
    leagueDetails: LeagueDetails[];
};

const LeagueGroupColumn = (props: Props) => {
    const leagueGroupLogo = useLeagueGroupLogo(props.leagueGroup);

    return (
        <LeagueGroupColumnContainer>
            <div className="group-title">
                <IconDecorated sizeRem={1.5} name={leagueGroupLogo} />
                {props.leagueGroup}
            </div>
            <div className="group-list">
                {props.leagueDetails.map((leagueDetails) => (
                    <LeagueItem key={leagueDetails.league} leagueDetails={leagueDetails} />
                ))}
            </div>
        </LeagueGroupColumnContainer>
    );
};

export default LeagueGroupColumn;
