import * as React from 'react';

import { IconKeys } from 'components/icons';
import { LeagueGroup } from 'types';

export function useLeagueGroupLogo(group: LeagueGroup): IconKeys {
    return React.useMemo(() => {
        switch (group) {
            case LeagueGroup.BASKETBALL_PRO:
            case LeagueGroup.BASKETBALL_NCAA:
                return 'SportsBasketballRounded';
            case LeagueGroup.FOOTBALL:
                return 'SportsFootballRounded';
            case LeagueGroup.HOCKEY:
                return 'SportsHockey';
            case LeagueGroup.OTHER:
            default:
                return 'Scoreboard';
        }
    }, [group]);
}
