import { ReactComponent as IconShield } from 'assets/svg/icon-shield.svg';
import logoMlb from 'assets/svg/logo-mlb.svg';
import logoNascar from 'assets/svg/logo-nascar.svg';
import logoNba from 'assets/svg/logo-nba.svg';
import logoNbdl from 'assets/svg/logo-nbdl.svg';
import logoNcaa from 'assets/svg/logo-ncaa.svg';
import logoNfl from 'assets/svg/logo-nfl.svg';
import logoNhl from 'assets/svg/logo-nhl.svg';
import logoWnba from 'assets/svg/logo-wnba.svg';
import LazyImage from 'components/lazy-image';
import { LeagueType } from 'types';

type LeagueLogoMap = Partial<{ [league in LeagueType]: string }>;

const leagueLogoMap: LeagueLogoMap = {
    cfb: logoNcaa,
    ncaafb: logoNcaa,
    nfl: logoNfl,
    nba: logoNba,
    wnba: logoWnba,
    nbdl: logoNbdl,
    ncaamb: logoNcaa,
    ncaawb: logoNcaa,
    nhl: logoNhl,
    ncaamh: logoNcaa,
    mlb: logoMlb,
    'nascar-ot3': logoNascar,
};

type Props = {
    league: LeagueType;
};

const LeagueLogo = ({ league }: Props) => {
    return (
        <LazyImage
            data-testid="league-logo"
            fallback={<IconShield />}
            src={leagueLogoMap[league]}
        />
    );
};

export default LeagueLogo;
