import { QueryClient } from 'react-query';
import { WSBackendEvent } from 'ws/types';

import { Games } from 'api/types/bff';
import { LeagueKeys } from 'hooks/queries';

import { MessageProcessor } from '../types';

import { BFFTopicTypes } from './types';

export type BFFGamesUpdateMessage = WSBackendEvent<BFFTopicTypes.GAMES_UPDATE, Games.Game>;

export const gamesUpdateProcessor: MessageProcessor<BFFGamesUpdateMessage> = async (
    queryClient: QueryClient,
    { payload: game }: BFFGamesUpdateMessage,
): Promise<void> => {
    const queriesData = queryClient.getQueriesData<Games.Game[] | undefined>(
        LeagueKeys.getGamesPartial(game.league),
    );

    // get all the queries related to this game
    queriesData.forEach((queryData) => {
        const queryKey = Array.from(queryData[0]) as Array<string | undefined>;

        const qKstatus = queryKey.find((key) => key?.startsWith('status'))?.split(':')[1];
        const qKdivision = queryKey.find((key) => key?.startsWith('division'))?.split(':')[1];
        const qKcoverage = queryKey.find((key) => key?.startsWith('coverage'))?.split(':')[1];

        // there are filters on this page so we need to take them into account
        if (qKstatus && qKcoverage && qKdivision) {
            // if the game matches the filters add it to the cache if not remove it
            if (
                ['all', game.status].includes(qKstatus) &&
                ['all', game.coverage].includes(qKcoverage) &&
                ['all', game.awayTeamDivision, game.homeTeamDivision].includes(qKdivision)
            ) {
                queryClient.setQueryData<Games.Game[] | undefined>(queryKey, (oldData) => {
                    if (!oldData) {
                        return [game];
                    }

                    // game doesn't exist in cache so add it
                    if (!oldData.find((oldGame) => oldGame.id === game.id)) {
                        return [...oldData, game];
                    }

                    // game exists in cache so update it
                    return oldData.map((oldGame) => {
                        if (oldGame.id === game.id) {
                            return game;
                        }

                        return oldGame;
                    });
                });
            } else {
                queryClient.setQueryData<Games.Game[] | undefined>(queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }

                    return oldData.filter((oldGame) => oldGame.id !== game.id);
                });
            }
        }
    });
};
