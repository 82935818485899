import { QueryClient } from 'react-query';
import { WSBackendEvent } from 'ws/types';

import { FlagType } from 'api/types/bff';
import { FlagKeys } from 'hooks/useFlagStats/queries';
import { LeagueType, SeasonType, SeasonYear } from 'types';

import { MessageProcessor } from '../types';

import { BFFTopicTypes } from './types';

export enum Operation {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export type BFFFlagsUpdateMessage = WSBackendEvent<
    BFFTopicTypes.FLAGS_UPDATE,
    {
        operation: Operation;
        league: LeagueType;
        seasonYear: SeasonYear;
        seasonType: SeasonType;
        week?: string;
        contextObjectSrId: string;
        flagId: string;
    }
>;

export const flagsUpdateProcessor: MessageProcessor<BFFFlagsUpdateMessage> = async (
    queryClient: QueryClient,
    message: BFFFlagsUpdateMessage,
): Promise<void> => {
    const params = message.payload;
    const paramsByContextId = { ...params, contextObjectId: params.contextObjectSrId };

    if (message.payload.operation === Operation.DELETE) {
        queryClient.refetchQueries(FlagKeys.flagsStats.getFlagsStatsByContextId(paramsByContextId));
        queryClient.invalidateQueries(FlagKeys.flagsStats.getFlagsStats(params));
        queryClient.removeQueries(FlagKeys.flagDiscrepancies.getFlagDiscrepancies(params));
        queryClient
            .getQueriesData<FlagType[]>(FlagKeys.flags.getFlagsByContextId(paramsByContextId))
            .forEach(([queryKey, queryData]) => {
                queryClient.setQueryData(
                    queryKey,
                    queryData.filter((flag) => flag.id !== message.payload.flagId),
                );
            });
    }

    if (message.payload.operation === Operation.CREATE) {
        queryClient.refetchQueries(FlagKeys.flags.getFlagsByContextId(paramsByContextId));
        queryClient.refetchQueries(FlagKeys.flagsStats.getFlagsStatsByContextId(paramsByContextId));
        queryClient.invalidateQueries(FlagKeys.flagsStats.getFlagsStats(params));
    }

    if (message.payload.operation === Operation.UPDATE) {
        queryClient.refetchQueries(FlagKeys.flags.getFlagsByContextId(paramsByContextId));
        queryClient.refetchQueries(FlagKeys.flagsStats.getFlagsStatsByContextId(paramsByContextId));
        queryClient.refetchQueries(FlagKeys.flagDiscrepancies.getFlagDiscrepancies(params));
        queryClient.invalidateQueries(FlagKeys.flagsStats.getFlagsStats(params));
    }
};
