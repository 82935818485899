import { CircularProgress } from '@mui/material';
import { mqtt5 } from 'aws-iot-device-sdk-v2';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { Route } from 'type-route';

import { Admin } from 'api/types/bff';
import { DiscrepancyStatusFilter } from 'api/types/flags';
import { routes } from 'app/router';
import { useSubscribeTopic } from 'app/useSubscribeTopic';
import ControlBar from 'components/control-bar';
import Dropdown from 'components/dropdown';
import Flag from 'components/flag-accordion/flag';
import FlagStats from 'components/flag-stats';
import FlagSystemEvents from 'components/flag-system-events';
import Loading from 'components/loading';
import Tooltip from 'components/primitives/tooltip';
import { useDiscrepancyStatusOptions } from 'hooks/options/useDiscrepancyStatusOptions';
import useAdminScheduleMutation from 'hooks/useAdminScheduleMutation';
import { useChangeParams } from 'hooks/useChangeParams';
import useFlags from 'hooks/useFlagStats/useFlags';
import { ContextType } from 'types';

import { useGameFlagStats } from '../../hooks/useGameFlagStats';
import DirectLinksForm, {
    AWAY_URL_AWAY_HOME_SWAPPED,
    FormData,
    HOME_URL_AWAY_HOME_SWAPPED,
} from '../game-direct-links';

import { useDirectLinks, useDirectLinksMutation } from './hooks/useDirectLinks';
import { SectionContainer } from './styles';

type Props = {
    route: Route<typeof routes.gameBoxscoresGame>;
    awayName?: string;
    homeName?: string;
};

const PostGameSection = ({ route, awayName, homeName }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const statusOptions = useDiscrepancyStatusOptions(route.params.status);

    const flagStats = useGameFlagStats({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        gameId: route.params.id,
    });

    const flagsQuery = useFlags({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        contextObjectId: route.params.id,
        flagType: 'boxscore',
        discrepancyStatus: route.params.status,
    });
    const flag = flagsQuery.data?.at(0);

    const topic = React.useMemo(() => {
        return `/flag-change/{league}/{seasonYear}/{seasonType}/{contextObjectSrId}`
            .replace('{league}', route.params.league)
            .replace('{seasonYear}', route.params.seasonYear)
            .replace('{seasonType}', route.params.seasonType)
            .replace('{contextObjectSrId}', route.params.id);
    }, [route.params.id, route.params.league, route.params.seasonType, route.params.seasonYear]);

    useSubscribeTopic('bff', topic, mqtt5.QoS.AtLeastOnce);

    const handleParamsChange = useChangeParams(route);

    const adminMutation = useAdminScheduleMutation();

    const handleStatusChange = React.useCallback(
        (status: DiscrepancyStatusFilter) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    const onSuccessfulLinkUpdate = useCallback(
        () => enqueueSnackbar('Direct links have been updated', { variant: 'success' }),
        [enqueueSnackbar],
    );

    const onErrorLinkUpdate = useCallback(
        (error) => {
            let msg = 'Failed to update direct links';

            if (axios.isAxiosError(error) && error?.response?.status === 409) {
                msg = error?.response?.data ?? msg;
            }

            enqueueSnackbar(msg, { variant: 'error' });
        },
        [enqueueSnackbar],
    );

    const directLinksMutation = useDirectLinksMutation(route.params.league, route.params.id, {
        onSuccess: onSuccessfulLinkUpdate,
        onError: onErrorLinkUpdate,
    });

    const { data: urls, isLoading: isLoadingLinks } = useDirectLinks(
        route.params.league,
        route.params.id,
    );

    const [directLinksFormOpen, setDirectLinksFormOpen] = React.useState(false);

    const handleDirectLinksFormOpen = React.useCallback(() => {
        setDirectLinksFormOpen(true);
    }, []);

    const handleDirectLinksFormClose = React.useCallback(() => {
        setDirectLinksFormOpen(false);
    }, []);

    const handleDirectLinksFormSubmit = ({ awayUrl, homeUrl, awayHomeSwaps }: FormData) => {
        directLinksMutation.mutate({
            league: route.params.league,
            seasonYear: route.params.seasonYear,
            seasonType: route.params.seasonType,
            gameId: route.params.id,
            urls: {
                awayUrl: awayUrl?.length === 0 ? null : awayUrl,
                awayUrlAwayHomeSwapped: awayHomeSwaps.includes(AWAY_URL_AWAY_HOME_SWAPPED),
                homeUrl: homeUrl?.length === 0 ? null : homeUrl,
                homeUrlAwayHomeSwapped: awayHomeSwaps.includes(HOME_URL_AWAY_HOME_SWAPPED),
            },
        });
        setDirectLinksFormOpen(false);
    };

    const onRepullClick = React.useCallback(() => {
        adminMutation.mutate({
            league: route.params.league,
            seasonType: route.params.seasonType,
            seasonYear: route.params.seasonYear,
            id: route.params.id,
            scheduleType: Admin.ScheduleTypes.Game,
        });
    }, [
        adminMutation,
        route.params.id,
        route.params.league,
        route.params.seasonType,
        route.params.seasonYear,
    ]);

    return (
        <SectionContainer>
            <div className="header">
                <div className="controls">
                    <ControlBar spacing="large">
                        <Dropdown
                            label="Status"
                            options={statusOptions}
                            onChange={handleStatusChange}
                        />
                        {isLoadingLinks || directLinksMutation.isLoading ? (
                            <CircularProgress color="inherit" size={'1rem'} />
                        ) : (
                            <>
                                <ControlBar.Button onClick={handleDirectLinksFormOpen}>
                                    Edit
                                </ControlBar.Button>
                                <DirectLinksForm
                                    open={directLinksFormOpen}
                                    onClose={handleDirectLinksFormClose}
                                    onSubmit={handleDirectLinksFormSubmit}
                                    urls={urls}
                                    awayName={awayName}
                                    homeName={homeName}
                                />
                            </>
                        )}
                    </ControlBar>
                    <div className="actions-container">
                        <Tooltip
                            disabled={adminMutation.isLoading}
                            disableInteractive
                            title="Click to update the flag"
                        >
                            <ControlBar.Button
                                disabled={adminMutation.isLoading}
                                onClick={onRepullClick}
                            >
                                {adminMutation.isLoading ? 'Repulling...' : 'Repull'}
                            </ControlBar.Button>
                        </Tooltip>
                        <FlagSystemEvents
                            league={route.params.league}
                            seasonType={route.params.seasonType}
                            seasonYear={route.params.seasonYear}
                            contextObjectId={route.params.id}
                            context={ContextType.Boxscore}
                        />
                    </div>
                </div>
                <FlagStats flagged={flagStats.created} ignored={flagStats.ignored} />
            </div>
            {flagsQuery.isLoading && <Loading />}
            {flag && <Flag flag={flag} isToggled status={route.params.status} layout="columns" />}
        </SectionContainer>
    );
};

export default PostGameSection;
