import { QueryClient } from 'react-query';
import { WSBackendEvent } from 'ws/types';

import { MessageProcessor } from '../types';

import { BFFFlagsUpdateMessage, flagsUpdateProcessor } from './flags-update';
import { BFFGameUpdateMessage, gameUpdateProcessor } from './game-update';
import { BFFGamesUpdateMessage, gamesUpdateProcessor } from './games-update';
import { BFFTopicTypes } from './types';

export const bffProcessor: MessageProcessor<WSBackendEvent> = async (
    queryClient: QueryClient,
    message: WSBackendEvent,
): Promise<void> => {
    switch (message.type) {
        case BFFTopicTypes.FLAGS_UPDATE:
            return flagsUpdateProcessor(queryClient, message as BFFFlagsUpdateMessage);
        case BFFTopicTypes.GAME_UPDATE:
            return gameUpdateProcessor(queryClient, message as BFFGameUpdateMessage);
        case BFFTopicTypes.GAMES_UPDATE:
            return gamesUpdateProcessor(queryClient, message as BFFGamesUpdateMessage);
        default:
            console.error(
                'BffProcessor: unknown message type: %s',
                (message as WSBackendEvent).type,
            );
    }
};
