import leaguesDetails from 'constants/leagues-details';
import { useGroupBy } from 'hooks/useGroupBy';
import { LeagueGroup } from 'types';

import LeagueGroupColumnContainer from './components/league-group-column';
import { HomeContainer } from './styles';

const Home = () => {
    const leagueGroups = useGroupBy(leaguesDetails, (details) => details.group);

    return (
        <HomeContainer>
            {Object.entries(leagueGroups.groups).map(([group, details]) => (
                <LeagueGroupColumnContainer
                    key={group}
                    leagueGroup={group as LeagueGroup}
                    leagueDetails={details}
                />
            ))}
        </HomeContainer>
    );
};

export default Home;
