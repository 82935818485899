import { QueryClient } from 'react-query';
import { WSBackendEvent } from 'ws/types';

import { Game } from 'api/types/bff';
import { LeagueKeys } from 'hooks/queries';

import { MessageProcessor } from '../types';

import { BFFTopicTypes } from './types';

export type GameWithLeague = Game.LiveGame & { league: string };

export type BFFGameUpdateMessage = WSBackendEvent<BFFTopicTypes.GAME_UPDATE, GameWithLeague>;

export const gameUpdateProcessor: MessageProcessor<BFFGameUpdateMessage> = async (
    queryClient: QueryClient,
    { payload: game }: BFFGameUpdateMessage,
): Promise<void> => {
    const queriesData = queryClient.getQueriesData<
        (Game.LiveGame & { league: string }) | undefined
    >(LeagueKeys.getGamePartial(game.league, game.srId));

    queriesData.forEach((queryData) => {
        const queryKey = Array.from(queryData[0]) as Array<string | undefined>;

        queryClient.setQueryData<Game.Game | undefined>(queryKey, (oldData) => {
            if (!oldData) {
                return {
                    ...game,
                    liveFlags: [],
                    flagStats: {
                        CREATED: 0,
                        IGNORED: 0,
                    },
                };
            }

            return {
                ...oldData,
                ...game,
            };
        });
    });
};
