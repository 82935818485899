import styled from '@emotion/styled';

export const HomeContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
    width: 100%;
    padding: 2.5rem 5%;

    & > * {
        flex-grow: 1;
    }
`;
