import styled from '@emotion/styled';

export const LeagueGroupColumnContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > .group-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 1.125rem;
        min-height: 3.875rem;
        margin: 0 0 0.5rem 0.5rem;
        font-family: Calistoga, serif;
        font-size: 1.5rem;
        font-size-adjust: 0.5;
        font-weight: 400;
        line-height: 1.9375rem;
    }

    & > .group-list {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 2.25rem;
    }

    &:not(:last-child) > .group-list::after {
        content: '';
        display: block;
        position: absolute;
        width: 0.125rem;
        top: 0;
        right: -1.5rem;
        bottom: 0;
        background-color: var(--color-borderline-secondary);
    }
`;
