import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { FlagType } from 'api/types/bff';

import { FlagKeys, GetFlagsParameters } from './queries';

const getFlags = async ({
    conferenceAlias,
    contextObjectId,
    discrepancyStatus,
    divisionAlias,
    flagType,
    league,
    seasonType,
    seasonYear,
    sortBy,
    sortOrder,
    week,
    dateFrom,
    dateTo,
    pollsWeek,
}: GetFlagsParameters): Promise<FlagType[]> => {
    const urlSearchParams = new URLSearchParams({
        ...(conferenceAlias && { conferenceAlias }),
        ...(contextObjectId && { contextObjectId }),
        ...(divisionAlias && { divisionAlias }),
        ...(flagType && { flagType }),
        ...(discrepancyStatus && { discrepancyStatus: discrepancyStatus.toUpperCase() }),
        ...(sortBy && { sortBy }),
        ...(sortOrder && { sortOrder }),
        ...(week && { week }),
        ...(dateFrom && { dateFrom }),
        ...(dateTo && { dateTo }),
        ...(pollsWeek && { pollsWeek }),
        seasonType,
        seasonYear,
    });

    const response = await client.get(`${API.BFF_API}/${league}/flags?${urlSearchParams}`);

    return response?.data;
};

export default function useFlags(parameters: GetFlagsParameters) {
    return useQuery({
        queryKey: parameters.contextObjectId
            ? FlagKeys.flags.getFlagsByContextIdByFilters(parameters)
            : FlagKeys.flags.getFlagsByFilters(parameters),
        queryFn: () => getFlags(parameters),
        refetchOnWindowFocus: false,
    });
}
