import * as React from 'react';

import { IconKeys } from 'components/icons';
import { LeagueType } from 'types';

export function useLeagueLogo(league: LeagueType): IconKeys {
    return React.useMemo(() => {
        switch (league) {
            case 'ncaamb':
            case 'ncaawb':
            case 'nba':
            case 'wnba':
            case 'nbdl':
                return 'SportsBasketballRounded';
            case 'nfl':
            case 'cfb':
            case 'ncaafb':
                return 'SportsFootballRounded';
            case 'nhl':
            case 'ncaamh':
                return 'SportsHockey';
            case 'mlb':
                return 'SportsBaseball';
            case 'nascar-ot3':
                return 'DirectionsCar';
            default:
                return 'SportsFootballRounded';
        }
    }, [league]);
}
